import { MagicLink } from "../ui";
import styles from "./Eyebrow.module.scss";

export interface EyebrowProps {
  topic_name?: string;
  url?: string;
}

export const Eyebrow = ({ url, topic_name }: EyebrowProps) => {
  return !!topic_name ? (
    <nav data-cy="eyebrow" className={styles.componentEyebrow}>
      {!!url ? <MagicLink to={url}>{topic_name}</MagicLink> : <>{topic_name}</>}
    </nav>
  ) : null;
};

export default Eyebrow;
